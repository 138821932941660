import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { graphql, useStaticQuery } from 'gatsby';
import { filterByLocale } from 'utils/filterDataByLocale';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
  },
  image: {
    maxWidth: 140,
  },
  gridCard: {
    padding: theme.spacing(2),
    background: theme.palette.alternate.main,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  subtitle: {
    margin: theme.spacing(2, 0),
  },
}));

const QuickSearch = props => {
  const data = useStaticQuery(graphql`
    query {
      ecommerce: allContentfulPages(filter: { slug: { eq: "ecommerce" } }) {
        edges {
          node {
            node_locale
            title
            body {
              internal {
                content
              }
            }
            bodyExtra {
              internal {
                content
              }
            }
            imageOne {
              file {
                url
              }
            }
            imageTwo {
              file {
                url
              }
            }
            subTitleOne
            subBodyOne {
              internal {
                content
              }
            }
            subTitleTwo
            subBodyTwo {
              internal {
                content
              }
            }
            firstButton
            secondButton
          }
        }
      }
    }
  `);
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const filteredData = filterByLocale(data.ecommerce.edges);
  return (
    <div className={className} {...rest}>
      {filteredData.map((edge, index) => {
        return (
          <div key={index}>
            <SectionHeader
              title={edge.node.body.internal.content}
              subtitle={edge.node.bodyExtra.internal.content}
              data-aos="fade-up"
              align="left"
            />

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  className={classes.gridCard}
                  data-aos="fade-up"
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    justify={isMd ? 'flex-start' : 'center'}
                    alignItems="center"
                    xs={12}
                    md={6}
                  >
                    <Image
                      src={edge.node.imageOne.file.url}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    justify="space-between"
                    alignItems={isMd ? 'flex-start' : 'center'}
                    xs={12}
                    md={6}
                    direction="column"
                  >
                    <Typography
                      variant="h6"
                      className={classes.title}
                      color="textPrimary"
                      align={isMd ? 'left' : 'center'}
                    >
                      {edge.node.subTitleOne}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      align={isMd ? 'left' : 'center'}
                      className={classes.subtitle}
                    >
                      {edge.node.subBodyOne.internal.content}
                    </Typography>
                    <Button color="primary" variant="contained">
                      {edge.node.firstButton}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid
                  container
                  className={classes.gridCard}
                  data-aos="fade-up"
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    justify={isMd ? 'flex-start' : 'center'}
                    alignItems="center"
                    xs={12}
                    md={6}
                  >
                    <Image
                      src={edge.node.imageTwo.file.url}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    justify="space-between"
                    alignItems={isMd ? 'flex-start' : 'center'}
                    xs={12}
                    md={6}
                    direction="column"
                  >
                    <Typography
                      variant="h6"
                      className={classes.title}
                      color="textPrimary"
                      align={isMd ? 'left' : 'center'}
                    >
                      {edge.node.subTitleTwo}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      align={isMd ? 'left' : 'center'}
                      className={classes.subtitle}
                    >
                      {edge.node.subBodyTwo.internal.content}
                    </Typography>
                    <Button color="primary" variant="contained">
                      {edge.node.secondButton}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

QuickSearch.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default QuickSearch;
